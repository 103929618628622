import * as React from "react"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"

interface IState {
  email: string
  submitted: boolean
  sending: boolean
  error: boolean
  recaptchaRef: any
}

export default class NewsletterForm extends React.Component<any, IState> {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      submitted: false,
      sending: false,
      error: false,
      recaptchaRef: React.createRef(),
    }
  }

  onSubmit = async () => {
    const { recaptchaRef } = this.state
    this.setState({ sending: true, error: false })
    try {
      const token = await recaptchaRef.current.executeAsync()
      const res = await axios({
        method: "POST",
        url: `/.netlify/functions/subscribe`,
        data: JSON.stringify({ email: this.state.email, token }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (res.data.status === `success`) {
        this.setState({ submitted: true, sending: false })
      } else {
        this.setState({ error: true, sending: false })
        recaptchaRef.current.reset()
      }
    } catch (err) {
      this.setState({ error: true })
      recaptchaRef.current.reset()
    }
  }

  handleChange = event => {
    this.setState({
      email: event.currentTarget.value,
    })
  }

  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  render() {
    const submitDisabled = !this.state.email || !this.validateEmail(this.state.email)
    return (
      <div className="col-xl-6 col-lg-6 col-md-6 contact newsletter-contact">
        <ReCAPTCHA
          sitekey={`6LeyYP0ZAAAAAKBnus3UbTEbnPodiSkX3H9l2o9T`}
          size="invisible"
          ref={this.state.recaptchaRef}
        />
        <div className="footer-wrapper pl-40 mb-30">
          <div className="footer-title">
            {/* <h4>Newsletter</h4> */}
            {/* <img src={imgF} alt="" /> */}
          </div>
          <div className="footer-content">
            <p>
              <i>Abonnez-vous pour ne rien manquer</i>
            </p>
          </div>
          <div className="subscribes-form">
            {this.state.submitted ? this.renderSubmitted() : this.renderForm(submitDisabled)}
          </div>
        </div>
      </div>
    )
  }

  renderForm(submitDisabled) {
    const { sending, error } = this.state
    return (
      <form action="#">
        <input placeholder="Entrez votre email " type="email" onChange={this.handleChange} />
        {sending ? (
          <button className="btn" disabled style={{ display: error ? "none" : "block" }}>
            En cours ...
          </button>
        ) : (
          <button className="btn" disabled={submitDisabled} onClick={this.onSubmit}>
            S'abonner
          </button>
        )}

        {error ? (
          <p className="error">Une erreur s'est produite. Merci de réessayer un peu plus tard.</p>
        ) : null}
      </form>
    )
  }

  renderSubmitted() {
    return (
      <>
        <h5 style={{ textAlign: "center", color: "#6b797a" }}>Vous êtes abonné !</h5>
        {/* <a href={"https://discord.gg/ZCD7ktEXn5"} target="_blank">
          Rejoignez la communauté sur Discord
        </a> */}
      </>
    )
  }
}
